<template>
  <div class="d-flex data-entry flex-column flex-grow-1">
    <resize-observer @notify="setScrollbarHeight" />
    <perfect-scrollbar id="container-scroll" :style="{height: scrollbarHeight}">
      <div class="approval conference-card">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <ifac-loader
                v-if="conferenceLoading"
                :style="{height: `${conferenceLoaderHeight}px`}"
              />
              <ifac-conference-header
                v-else
                is-owner
                with-pdf
                header-type="approval"
                :countries="countries"
                :conference="conference"
                :is-liaison="isCbLiaison"
                :liaison-tree="mx_cbLiaison"
                :is-conference-board="mx_isCbChair"
              />
            </div>
          </div>
        </div>
        <div class="card-body" v-if="displayCommentsForm">
          <ifac-loader
            v-if="approvalTreesLoading"
            :style="{height: approvalLoaderHeight}"
          />
          <div class="row" v-else>
            <div class="col">
              <!-- ANSWERS -->
              <ifac-approval-answers-form
                is-owner
                :conference="conference"
                :answers="approval.meta_answers"
                v-if="approvalIfForTcChair && approval"
                @change-answers="approval.meta_answers = $event"
              />
              <!-- COMMENTS FORM -->
              <ifac-approval-comments-form
                :affiliate="user"
                :approval="approval"
                :conference="conference"
                :position-name="positionName"
                :position-label="positionLabel"
                @approval-status="approval.response = $event"
                @approval-comments="approval.notes = $event"
                @approval-comments-on-amendments="approval.notes_on_amendments = $event"
                @approval-comments-on-terminated="approval.notes_on_terminated = $event"
              />
            </div>
          </div>
          <div class="row submit-buttons">
            <div class="col text-right right-col p-0">
              <button
                type="submit"
                class="btn btn-secondary"
                :disabled="!hasResponse"
                @click="saveOnly"
              >
                Save
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="buttonIsDisabled"
                @click="saveAndSubmit"
              >
                Submit evaluation
              </button>
            </div>
          </div>
        </div>
      </div>
      <main-footer ref="footer"></main-footer>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Approvals from '@/services/Api/Approvals';
import {
  IfacLoader,
  ifacUiMixin,
  ifacPositionsMixin,
  IfacConferenceHeader,
  IfacApprovalAnswersForm,
  IfacApprovalCommentsForm,
  ifacApprovalTreeMixin,
  IfacConferencesHelpers,
  IfacPositionHelpers,
} from '@ifac/ui';
import MainFooter from '@/views/layouts/MainFooter.vue';

export default {
  name: 'ApprovalsEdit',
  components: {
    IfacLoader,
    IfacConferenceHeader,
    IfacApprovalCommentsForm,
    IfacApprovalAnswersForm,
    MainFooter,
  },
  mixins: [
    ifacUiMixin,
    ifacApprovalTreeMixin,
    ifacPositionsMixin,
  ],
  props: {
    approvalTrees: {
      type: Array,
    },
    conference: {
      type: Object,
    },
    conferenceLoading: {
      type: Boolean,
      default: false,
    },
    approvalTreesLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    approvalTrees: {
      deep: true,
      handler(data) {
        this.setApprovalData(data);
      },
    },
    approvalIfForTcChair: {
      handler(data) {
        this.setSpeechBubbleDisabled(data);
      },
    },
  },
  data() {
    return {
      approval: null,
      position: null,
      scrollbarHeight: 0,
      hasAnswers: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      countries: (state) => state.countries.data,
      currentPositions: (state) => state.auth?.user?.positions,
    }),
    approvalIfForTcChair() {
      return this.position?.entityType === 'technicalCommittee';
    },
    positionName() {
      if (this.isCbLiaison) {
        return IfacPositionHelpers.cbLiaison.name;
      }

      const position = this.mx_formattedPositions.find((p) => {
        const positionId = this.position?.id;
        if (p.role) return p.role.id === positionId;
        if (p.subcategory) return p.subcategory.id === positionId;
        return p.category.id === positionId;
      });

      return this.mx_getPositionNameWithoutTriennium(position);
    },
    displayCommentsForm() {
      if (this.isCbChair) {
        // Display only if the conference has finnacial support
        // and the CB Liaison has submitted his approval.
        return this.hasFinancialRequest
          && !!this.mx_cbLiaison?.approval?.submitted_at;
      }

      if (this.isCbLiaison) {
        // Display only if the conference has finnacial support.
        return this.hasFinancialRequest;
      }

      return true;
    },
    isCbChair() {
      return this.mx_cbChair?.approval?.id === this.mx_approvalId;
    },
    isCbLiaison() {
      return this.mx_cbLiaison?.approval?.id === this.mx_approvalId;
    },
    positionLabel() {
      return this.isCbLiaison
        ? IfacPositionHelpers.cbLiaison.label
        : (this.position?.entityType || this.position?.name);
    },
    conferenceId() {
      return this.$route.params.conferenceId;
    },
    answersHaveErrors() {
      if (this.approvalIfForTcChair && (this.approval?.hasAnswers === false && this.hasAnswers === null)) {
        return true;
      }

      const errors = this.approval?.meta_answers?.errors;

      return errors
        ? Object.keys(errors).length > 0
        : false;
    },
    isAlreadySubmitted() {
      return !!(this.approval?.submitted_at);
    },
    hasResponse() {
      return !!this.approval?.response;
    },
    buttonIsDisabled() {
      return this.answersHaveErrors || this.isAlreadySubmitted || !this.hasResponse;
    },
    conferenceLoaderHeight() {
      return 110;
    },
    approvalLoaderHeight() {
      return `calc(100vh - ${this.mx_upperZoneHeight + this.conferenceLoaderHeight}px)`;
    },
    hasFinancialRequest() {
      return IfacConferencesHelpers
        .hasFinancialRequest(this.conference);
    },
  },
  mounted() {
    this.setScrollbarHeight();
    this.setSpeechBubbleDisabled(this.approvalIfForTcChair);
  },
  methods: {
    ...mapMutations({
      setIsCbNode: 'uiHelpers/setIsCbNode',
      setIsCbLiaisonNode: 'uiHelpers/setIsCbLiaisonNode',
      setIsTbChairNode: 'uiHelpers/setIsTbChairNode',
      setRequestError: 'formHelpers/setError',
      setSpeechBubbleDisabled: 'uiHelpers/setSpeechBubbleDisabled',
    }),
    setScrollbarHeight() {
      this.scrollbarHeight = `calc(100vh - ${this.mx_upperZoneHeight}px)`;
    },
    goToMyApprovals() {
      this.$router.push({ name: 'Approvals' });
    },
    sanitizeList(list) {
      return list.filter((o) => o);
    },
    preparePayload() {
      if (this.approval?.meta_answers) {
        const recommendation = {
          ...this.approval.meta_answers.data.organizationalLeadership.recommendation,
        };
        const mainList = this.sanitizeList(recommendation.main);

        this.approval.meta_answers.data.organizationalLeadership.recommendation.main = mainList;
      }

      return this.approval;
    },
    execErrorBlock(error, action) {
      let ms = `There was some error ${action} the approval,`;
      if (action === 'submitting') {
        ms = 'Form saved but not submitted,';
      }

      if (error.response?.status === 422) {
        this.$snack.success({ text: `${ms} check the form error flags please.` });
        this.setRequestError(error.response.data.error);
      } else {
        this.$snack.success({ text: `${ms} please try again.` });
      }
    },
    execSuccessBlock(data, action) {
      this.approval = data;
      this.setRequestError(null);

      if (action === 'saved_with_errors') {
        this.$snack.success({ text: 'Form saved but has errors.' });
        return;
      }

      this.$snack.success({
        text: `Your approval was ${action} successfully!`,
      });
    },
    async saveOnly() {
      try {
        const response = await Approvals.update(this.preparePayload());
        const approvalData = response?.data?.data;

        if (response?.status === 200 && approvalData) {
          this.hasAnswers = approvalData.hasAnswers;
          let action = 'updated';

          if (approvalData.hasErrors === true) {
            action = 'saved_with_errors';
          }

          this.execSuccessBlock(approvalData, action);
        }
      } catch (error) {
        console.log(error);
        this.execErrorBlock(error, 'updating');
      }
    },
    async saveAndSubmit() {
      try {
        const saveResponse = await Approvals.update(this.preparePayload());
        const saveData = saveResponse?.data?.data;

        if (saveResponse?.status === 200 && saveData) {
          this.approval = saveData;

          try {
            const submitResponse = await Approvals.submit(this.mx_approvalId);
            const submitData = submitResponse?.data?.data;

            this.approval = submitData;

            if (submitResponse?.status === 200) {
              this.execSuccessBlock(submitData, 'submitted');
              this.goToMyApprovals();
            }

            if (submitResponse?.status === 422) {
              this.execErrorBlock(submitData, 'submitting');
            }
          } catch (error) {
            this.execErrorBlock(error, 'submitting');
          }
        }
      } catch (error) {
        this.execErrorBlock(error, 'updating');
      }
    },
    setInitialData() {
      const cbNodesList = [
        ...[this.mx_cbChair],
        ...[this.mx_cbLiaison],
      ];

      const nodesList = [
        ...cbNodesList,
        ...[this.mx_tbChair],
        ...this.mx_tbVCs,
        ...this.mx_cCs,
        ...this.mx_tCs,
      ];

      const data = nodesList.find((n) => n.approval?.id === this.mx_approvalId);

      this.approval = data?.approval;
      this.position = data?.position;

      this.setIsCbNode(
        !!cbNodesList.find((n) => n.approval?.id === this.mx_approvalId),
      );

      this.setIsCbLiaisonNode(
        this.mx_cbLiaison?.approval?.id === this.mx_approvalId,
      );

      this.setIsTbChairNode(
        this.mx_tbChair?.approval?.id === this.mx_approvalId,
      );
    },
    setApprovalData() {
      this.mx_breakdownTreeNodes(this.approvalTrees);
      this.setInitialData();
    },
  },
};
</script>

<style lang="scss">
  @import '@/assets/styles/base/_variables.scss';
  @import '@/assets/styles/base/_mixins.scss';

  .data-entry {
    .submit-buttons {
      border-top: 0;

      @include media(mobile) {
        .right-col {
          .btn {
            width: 100%;
            margin-left: 0;

            &:last-of-type {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
</style>
